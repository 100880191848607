<template>
  <v-container
    style="padding: 0 0 0 20px 0; border-radius: 5px; background:white;"
  >
    <div v-if="loading">
      <v-card
        flat
        style="
          border-bottom: 1px solid black;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div
          style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
        >
          <v-toolbar-title class="overline"
            ><p style="font-size: 20px; margin: 0; font-weight: bold">
              Detail Pengeluaran Dokumen
            </p>
          </v-toolbar-title>
        </div>
        <div style="display: flex">
          <div v-if="documentDetail !== null">
            <!-- <div v-if="documentDetail.document_type === 'Soft Copy'"> -->
            <div v-if="result.status.id === 0">
              <v-btn
                v-if="isAllow"
                @click="submitForm"
                tile
                color="green"
                small
                class="ma-2"
                style="color: white"
              >
                Approve
              </v-btn>
            </div>
            <!-- </div> -->
          </div>
          <!-- <div v-if="documentDetail !== null">
            <div v-if="documentDetail.document_type === 'Soft Copy'">
              <div v-if="result.status.id === 9">
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ application, is_admin }) =>
                        (application.id === 1 || application.id === 14) &&
                        is_admin === 0
                    ) !== undefined
                  "
                  @click="downloadSoftCopy(result)"
                  tile
                  color="green"
                  small
                  class="ma-2"
                  style="color: white"
                >
                  Download <v-icon small>mdi-download</v-icon>
                </v-btn>
              </div>
            </div>
          </div> -->
          <!-- <div v-if="result.status.id === 2">
            <v-btn
              v-if="
                this.getUserProfile.level.find(
                  ({ id }) =>
                    id === '1' ||
                    id === (build === 'development' ? '19' : '20') ||
                    id === (build === 'development' ? '20' : '21')
                ) !== undefined
              "
              small
              tile
              color="green"
              class="ma-2"
              style="color: white"
              @click="printTTP(result)"
            >
              Print Tanda Terima
            </v-btn>
          </div> -->
          <v-btn
            small
            tile
            color="black"
            class="ma-2"
            style="color: white"
            @click="close"
          >
            Close
          </v-btn>
        </div>
      </v-card>

      <v-row style="padding: 15px">
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                No. Pengeluaran
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.loan_number"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Status
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.status !== null ? result.status.name : 'null'"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Penangung Jawab
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                v-if="result.doc.document_type_id === 1"
                :value="result.pic_name"
                dense
                readonly
                style="margin: 0"
              />
              <v-text-field
                v-if="result.doc.document_type_id === 2"
                :value="result.employee.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Email
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.email"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Plant
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.plant.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Department
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.department.name"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="this.getLoaningDocId[1]"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                No. Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.doc.doc_no"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Jumlah
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.qty"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="2" style="padding: 0"></v-col>
        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Uom
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="result.qty_uom !== null ? result.qty_uom.name : ''"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>

        <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Pengeluaran
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="convertDate(result.loan_start_date)"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="7" style="padding: 0"></v-col>
        <!-- <v-col md="5" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Tgl Pengembalian
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="convertDate(result.loan_end_date)"
                dense
                readonly
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col> -->

        <v-col md="12" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Alasan Dikeluarkan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-textarea
                :value="result.loan_purpose"
                readonly
                dense
                auto-grow
                outlined
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <hr />

      <v-row style="padding: 15px; margin-top: 10px; margin-bottom: 20px">
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Kepemilikan Dokumen
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="
                  documentDetail !== null
                    ? documentDetail.plant !== null
                      ? documentDetail.plant.name
                      : ''
                    : ''
                "
                readonly
                dense
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="1" style="padding: 0"></v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                PIC
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="
                  documentDetail !== null
                    ? documentDetail.pic_department_name
                    : ''
                "
                readonly
                dense
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
        <v-col md="1" style="padding: 0"></v-col>
        <v-col md="3" style="padding: 0">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Kode Brankas
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-text-field
                :value="
                  documentDetail !== null ? documentDetail.rack_index : ''
                "
                readonly
                dense
                style="margin: 0"
              />
            </v-col>
          </div>
        </v-col>
      </v-row>

      <!-- <div v-if="result.status !== null">
        <ReturnDocument v-if="result.status.id === 8" :loanId="result.id" />
      </div> -->

      <div v-if="documentDetail !== null">
        <v-card
          v-if="documentDetail.document_type.id === 2"
          flat
          class=" mx-auto"
          style="padding:10px 5px 20px 5px; width:100%; margin-top:1px; margin-bottom:10px;"
        >
          <v-data-table
            :headers="headersMatrix"
            :items="matrix"
            class="elevation-1 mx-auto"
            style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
            :items-per-page="10"
            hide-default-footer
          >
            <template v-slot:[`item.approver_user`]="{ item }">
              <div style="cursor:pointer;" v-if="item">
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  v-for="(item, index) in item.approver_user"
                  :key="index"
                >
                  {{ item.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === 1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.rejected`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === -1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <!-- <div style="text-align:right; padding-right:5px;">
          <v-btn
            v-if="
              getUserProfile.level.find(
                ({ application }) => application.id === 1
              ) !== undefined
            "
            color="grey"
            tile
            dark
            small
            dense
            style="font-size:10px; width:100px;"
            @click="fetch"
          >
            <span>Fetch Matrix</span>
          </v-btn>
        </div> -->
        </v-card>
      </div>
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'Archive-Return',
  props: ['param'],
  components: {
    // ReturnDocument: () => import('../return/returnDocument')
  },
  data() {
    return {
      oAuth: buildType.apiURL('oauth'),
      eSanqua: buildType.apiURL('esanqua'),
      download: buildType.apiURL('download'),
      build: process.env.VUE_APP_BUILD_TYPE,
      dialog: false,
      result: {},
      loading: false,
      select: null,
      search: null,
      itemss: [],
      new: [],
      documentDetail: null,

      itemsPerPage: 10,
      headers: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],
      isAllow: false,
      matrix: [],
      headersMatrix: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ]
    }
  },
  computed: {
    ...mapGetters(['getLoaningDocId', 'getUserProfile'])
  },
  created() {
    this.initDetail()
  },
  methods: {
    // ...mapActions(["userDropdown", "addApprover"]),
    ...mapMutations(['setLoaningDocId']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    initDetail() {
      this.loading = false
      axios
        .get(
          `${this.eSanqua}archive_document/loan/detail/${this.getLoaningDocId[0]}`
        )
        .then(res => {
          this.result = res.data.data
          console.log(this.result)
          setTimeout(() => {
            this.loading = true
          })
          this.initDetailArchive(res.data.data)
          this.authorityCheck(res.data.data)
          this.matrixCheck(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    initDetailArchive(item) {
      axios
        .get(`${this.eSanqua}archive_document/detail/${item.doc.id}`)
        .then(res => {
          this.documentDetail = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    rowClick(item) {
      console.log(item)
    },
    clear() {
      this.setLoaningDocId(null)
      this.$router.push('/archive-management/document-release/')
    },
    clickOutside() {
      this.clear()
    },
    close() {
      this.clear()
    },
    add() {},
    update() {},
    submitForm() {
      const form = {
        document_id: this.result.approval_matrix[0].document_id,
        status: 1
      }
      this.save(form)
    },
    createNewForm() {},
    save(form) {
      console.log(form)
      axios
        .post(`${this.eSanqua}archive_document/self_service_loan/confirm`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initDetail()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },

    authorityCheck(item) {
      const matrixId = item.approval_matrix[0].document_id
      axios
        .get(
          `${this.oAuth}approval_matrix/document/allow_user?document_id=${matrixId}`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            if (res.data.is_allow_approve !== 0) {
              this.isAllow = true
            } else {
              this.isAllow = false
            }
          } else {
            this.isAllow = false
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin'
          )
        })
    },
    matrixCheck(item) {
      axios
        .get(
          `${this.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=14&table_name=${item.approval_matrix[0].table_name}&document_id=${item.approval_matrix[0].document_id}`
        )
        .then(res => {
          console.log(res)
          if (res.data.status_code === '00') {
            this.matrix = res.data.data
          } else {
            this.showMsgDialog('error', res.data.status_msg)
          }
        })
        .catch(err => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin'
          )
        })
    },
    // fetch() {
    //     this.matrix = []
    //     this.fetchMatrix(this.result)
    //       .then(res => {
    //         if (res.status_code !== '-99') {
    //           this.showMsgDialog('success', res.status_msg)
    //           this.reloadPage()
    //         } else {
    //           if (res.status_msg === 'Parameter value has problem') {
    //             this.showMsgDialog('error', res.error_msg[0].msg)
    //           } else {
    //             this.showMsgDialog('error', res.status_msg)
    //           }
    //         }
    //       })
    //       .catch(err => {
    //         this.showMsgDialog(
    //           'error',
    //           err
    //             ? 'Something went wrong, please contact an Admin'
    //             : 'Something went wrong, please contact an Admin'
    //         )
    //       })
    // },

    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)

        switch (month) {
          case '01':
            month = 'JAN'
            break
          case '02':
            month = 'FEB'
            break
          case '03':
            month = 'MAR'
            break
          case '04':
            month = 'APR'
            break
          case '05':
            month = 'MAY'
            break
          case '06':
            month = 'JUN'
            break
          case '07':
            month = 'JUL'
            break
          case '08':
            month = 'AGS'
            break
          case '09':
            month = 'SEP'
            break
          case '10':
            month = 'OKT'
            break
          case '11':
            month = 'NOV'
            break
          case '12':
            month = 'DES'
            break
        }

        return day + ' ' + month + ' ' + year
      }
    },
    downloadSoftCopy(pValue) {
      console.log(pValue)
      axios({
        url: `${this.download}esanqua/archive_document/edocument/${pValue.edoc_token}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          console.log(res)
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${pValue.doc.doc_no}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    },
    printTTP(pValue) {
      console.log(pValue)
      axios({
        url: `${this.eSanqua}archive_document/loan/export_to_pdf/${pValue.id}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          console.log(res)
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Tanda_Terima_Arsip.pdf')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.docSpanDownload {
  border: 1px solid rgba(0, 0, 0, 0.568);
  font-weight: 500;
  border-radius: 3px;
  padding: 1px 2px;
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.384);
}
</style>
